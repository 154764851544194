<template>
  <validation-observer v-slot="{ handleSubmit }">
    <seller-form
      class="role"
      @submit="handleSubmit(onSubmit)"
    >
      <x-form-item
        v-slot="validationData"
        label="Название роли"
        name="Название роли"
        rules="required"
      >
        <x-input
          id="title"
          v-model="form.title"
          placeholder="Введите название"
          v-bind="validationData"
        />
      </x-form-item>

      <h5 class="role__permissions-heading">
        Доступ к разделам:
      </h5>

      <x-loader
        v-if="isLoading"
        class="role__loader"
      />

      <ul
        v-else-if="permissionsList.length"
        class="role__permissions-list"
      >
        <li class="role__permissions-item">
          <x-checkbox
            indeterminate
            :model-value="isFullAccess"
            @change="onFullAccessCheck"
          />

          <span class="role__permission-name">
            Полный доступ
          </span>
        </li>

        <li
          v-for="permission in permissionsList"
          :key="permission.id"
          class="role__permissions-item"
        >
          <x-checkbox
            :model-value="rolePermissionsIds"
            :value="permission.id"
            @change="value => onPermissionCheck(permission.id, value)"
          />

          <span class="role__permission-name">
            {{ permission.name }}
          </span>
        </li>
      </ul>

      <x-placeholder
        v-else
        icon="table-not-found"
        desc="Ничего не найдено"
      />

      <transition name="fade">
        <div
          v-if="showActions"
          class="role__actions"
        >
          <x-btn
            :to="$route.meta.back"
            color="gray"
          >
            Отменить
          </x-btn>

          <x-btn
            type="submit"
            :loading="isSubmitProccessed"
          >
            Сохранить
          </x-btn>
        </div>
      </transition>

      <success-create-modal>
        «{{ form.title }}» добавлена
      </success-create-modal>
    </seller-form>
  </validation-observer>
</template>

<script>
import SellerForm from '@/components/Interface/Form.vue'
import SuccessCreateModal from '@/components/Modals/SuccessCreateModal.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapState } = createNamespacedHelpers('settings/permissions')

const stocksPermissionId = 5
const productsPermissionId = 1

export default {
  components: {
    SellerForm,
    SuccessCreateModal
  },

  props: {
    id: {
      type: [String, Number],
      default: ''
    }
  },

  data () {
    return {
      form: {
        title: '',
        permissions: [],
        is_full_access: false
      },
      isLoading: false,
      isSubmitProccessed: false
    }
  },

  watch: {
    'form.is_full_access': {
      handler (checked) {
        if (checked) {
          this.form.permissions = this.permissionsList
        }
      },
      immediate: true
    },

    isFullAccess (value) {
      this.form.is_full_access = value
    }
  },

  computed: {
    ...mapState(['permissionsList']),

    showActions () {
      return this.form.title && (this.form.permissions.length || this.form.is_full_access)
    },

    rolePermissionsIds () {
      return this.form.permissions.map(p => p.id)
    },

    isFullAccess () {
      return this.permissionsList.every(p => this.rolePermissionsIds.includes(p.id))
    }
  },

  created () {
    this.fetchData()
  },

  methods: {
    ...mapActions([
      'getRole',
      'updateRole',
      'createRole',
      'getPermissionsList'
    ]),

    async fetchData () {
      if (!this.permissionsList.length) {
        await this.fetchPermissions()
      }

      if (this.id) {
        this.fetchRole()
      }
    },

    async fetchRole () {
      try {
        this.isLoading = true
        this.form = await this.getRole(this.id)

        this.$route.meta.heading = this.form.title
      } finally {
        this.isLoading = false
      }
    },

    async fetchPermissions () {
      try {
        this.isLoading = true
        await this.getPermissionsList()
      } finally {
        this.isLoading = false
      }
    },

    async onSubmit () {
      try {
        this.isSubmitProccessed = true

        if (this.id) {
          await this.updateRole(this.form)
          this.$toast.success('Данные сохранены')
          this.$router.push(this.$route.meta.back)
          return
        }

        await this.createRole(this.form)
        this.$modal.show('success-create-modal', {
          back: this.$route.meta.back
        })
      } finally {
        this.isSubmitProccessed = false
      }
    },

    // FIXME: Rework this when related_permissions
    // field on backed is ready
    onPermissionCheck (permissionId, ids) {
      this.form.permissions = this.permissionsList.filter(p => ids.includes(p.id))

      if (permissionId === stocksPermissionId && !ids.includes(productsPermissionId)) {
        const productsPermission = this.permissionsList.find(p => p.id === productsPermissionId)
        this.form.permissions.push(productsPermission)
      }

      if (permissionId === productsPermissionId && ids.includes(stocksPermissionId)) {
        const stocksPermissionIdx = this.form.permissions.findIndex(p => p.id === stocksPermissionId)
        this.form.permissions.splice(stocksPermissionIdx, 1)
      }
    },

    onFullAccessCheck (checked) {
      this.form.is_full_access = checked
      this.form.permissions = checked ? this.permissionsList : []
    }
  }
}
</script>

<style lang="stylus" scoped>
  .role
    width 430px

    &__permissions-heading
      margin 20px 0
      font-size $font-sizes.headline-5
      font-weight $font-weights.semibold

    &__permissions-list
      list-style none

    &__permissions-item
      display flex
      align-items center
      margin-bottom 12px

    &__permission-name
      margin-left 10px
      font-size $font-sizes.text

    &__actions
      display flex
      align-items center
      justify-content flex-end
      margin-top 20px

    &__loader
      margin-left 24px
      justify-content flex-start

  .fade-enter-active,
  .fade-leave-active
    transition opacity .2s ease-out

  .fade-enter,
  .fade-leave-to
    opacity 0
</style>
